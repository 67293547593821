







































import type { PropType } from 'vue';

export default {
  name: 'DashboardRewardTileModal',
  props: {
    tooltipText: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    header: {
      type: String as PropType<string>,
      default: (): string => ''
    }
  },
  data(): any {
    return {
      show: false
    };
  },
  methods: {
    closeDialog(): void {
      this.show = false;
    }
  }
};
