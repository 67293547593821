











































































import { DateTime } from 'luxon';
import { mapState, mapActions } from 'vuex';
import DashboardRewardsTile from '@/views/DashboardViews/dashboard-view/DashboardRewardsTile.vue';
import DashboardRewardTileModal from '@/views/DashboardViews/dashboard-view/DashboardRewardTileModal.vue';
import StakingViewPrograms from '@/views/DashboardViews/staking-view/StakingViewPrograms.vue';
import MiningWagers from '@/views/DashboardViews/mining-view/MiningWagers.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import type { TokensInfoState } from '@/store/modules/tokens-info/TokensInfoState';
import RewardsInfoMixin from '@/views/DashboardViews/dashboard-view/mixins/RewardsInfoMixin';

interface IBlockItem {
  tokenValue: string;
  currencyValue: string;
}

export default {
  name: 'DashboardRewards',
  components: {
    DashboardRewardsTile,
    DashboardRewardTileModal,
    StakingViewPrograms,
    MiningWagers,
    Tooltip
  },
  mixins: [RewardsInfoMixin],

  computed: {
    ...mapState('TokensInfo', {
      currency: (state: TokensInfoState) => state.tokenPrice.currency,
      rateCurrencyTicker: (state: TokensInfoState) =>
        state.tokenPrice.rateCurrencyTicker
    }),

    blocks(): { [key: string]: IBlockItem } {
      return Object.fromEntries(
        Object.entries({
          h2e: {
            tokenValue: this.rewardsInfo.totalH2e,
            currencyValue: this.rewardsInfo.totalH2eOperatorCurrency
          },
          p2e: {
            tokenValue: this.rewardsInfo.totalP2e,
            currencyValue: this.rewardsInfo.totalP2eOperatorCurrency
          },
          promo: {
            tokenValue: this.rewardsInfo.totalPromo,
            currencyValue: this.rewardsInfo.totalPromoOperatorCurrency
          },
          burn: {
            tokenValue: this.rewardsInfo.totalBurn,
            currencyValue: this.rewardsInfo.totalBurnOperatorCurrency
          }
        }).map(([key, value]) => [key, this.formatDataValues(value)])
      );
    },
    minDate(): string {
      return DateTime.utc().minus({ year: 1 }).toFormat('yyyy-LL-dd');
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getMiningStatus();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('Mining', ['getMiningStatus']),

    _formatValues([key, val]: [string, number[]]): [string, string] {
      const isToken = key === 'tokenValue';
      const currency = isToken ? this.currency : this.rateCurrencyTicker;
      const format = isToken ? `0,0` : '0,0.[000]';

      return [key, this.formatWithCurrency(`${val}`, currency, format)];
    }
  }
};
