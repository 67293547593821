




















import type { PropType } from 'vue';

export default {
  name: 'DashboardRewardsTile',
  props: {
    title: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    tokensValue: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    operatorCurrencyValue: {
      type: String as PropType<string>,
      default: (): string => ''
    }
  }
};
